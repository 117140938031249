import {
  Row,
  Col,
  Card,
  Space,
  Button,
} from 'antd';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import './index.css'
import { Link, useParams } from 'react-router-dom';
import { getFixtures } from '../../apis/fixture.api';
import { useEffect, useState } from 'react';
import { 
  CATEGORY_LINKS, 
  DEFAULT_LEAGUE_LINK, 
  DEFAULT_TEAM_LINK, 
  MAIN_EVENTS, 
  PAGE_TITLES, 
  categories } from '../../utils/constant';
import Loader from '../../components/common/Loader';
import Title from 'antd/es/typography/Title';
import { styles } from '../../styles';
// import AdSenseBanner from '../Ads';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { getTimer } from '../../utils/timer';
import MainDescription from '../Description';
import { getCategoryType } from '../../utils';
// import BCBanner from '../Ads/AffiliateBanner';
// import Teams from '../Teams';

  const cardStyle = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: '100%',
  };

  const headerStyle: any = {
    display: 'flex',
    justifyContent: 'flex-start',
  };

  const iconStyle = {
    color: '#adc6ff',
    marginRight: '10px',
    fontSize: '21px',
    strokeWidth: '15px',
    alignItems: 'middle'
  };

export default  function  Home() {
  const matches = useMediaQuery('(min-width: 1000px)');
  const [fixtures, setFixtures] = useState<any>({});
  const [noFixtures, setNoFixtures] = useState<boolean>(false);
  const { soccer, categoryName } = useParams();
  const [loader, setLoader] = useState<boolean>(false);
  const buttonSize = matches ? 'large': 'small';

  const getAllFixtures = async ()=> {
    setLoader(true);
    const newCat = soccer == 'home' ? 'soccer': soccer;
    const categoryObj = categories.find((cat: any) => cat.label === categoryName);
    const category = categoryObj?.value ?? newCat;

    const response: any = await getFixtures({ categoryName:  category });
    if(!response?.data?.subCategories[0]?.games?.length){
      setNoFixtures(true);
    }else{
      setNoFixtures(false);
    }
    setFixtures(response.data);
    setLoader(false);
  }

  function SubCategoryCard({ subCategory }: any) {
    return (
      <Col style={{marginBottom: '8px', justifyContent:'center'}} span={24}>
        <Card
          size='small'
          title={
            <div  style={headerStyle}>
              <span style={{display: 'flex', alignItems: 'center', color: '#6a0707', fontSize: '18px', textTransform: 'capitalize'}}>
              
              { subCategory?.subCategoryImage ? 
              <img style={{width: '30px', paddingRight: '10px'}} 
              src={subCategory?.subCategoryImage} 
              alt={subCategory?.subCategoryName} 
              />: <InfoCircleOutlined style={iconStyle} />}
              <span className={
                MAIN_EVENTS.includes(subCategory?.subCategoryName) ? 'blinking-heading': ''}>
                  {subCategory?.subCategoryName?.toUpperCase()}</span>
              </span>
            </div>
          }
          extra={!categoryName && CATEGORY_LINKS[subCategory?.subCategoryName?.toUpperCase()] && 
            <Link to={`${CATEGORY_LINKS[subCategory?.subCategoryName?.toUpperCase()]}`} 
            style={styles.more} className='more'> More</Link>}
          style={cardStyle}
        >
          <Row gutter={24}>
            {subCategory?.games?.map((fixture: any, index: any) => (
              <Col span={12} key={index}>
                <FixtureCard key={index} fixture={{...fixture, subCategoryName: subCategory?.subCategoryName}} />
              </Col>
              ))
            }
          </Row>
        </Card>
      </Col>
    );
  }

  function Timer({ timerValue }: any) {
    return (
      <strong style={timerValue === 'Live' ? { color: 'red' } : {}}>{timerValue}</strong>
    );
  }

  function FixtureCard({ fixture }: any) {
    return (
      <Link 
      to={fixture?.teamB ? '/fixture/' + fixture.teamA + '-vs-' + fixture.teamB: '/fixture/' + fixture.teamA}
      rel="noopener noreferrer"
      >
        <div className='fixture-card'>
          <div className='fixture-link'>
          <div className='category'>
              <p>
                {getCategoryType(fixture?.subCategoryName)}
              </p>
              <p className='dash'></p>
            </div>
            <div className='flex-display'>
              
              {
              matches ? <>
                <p className='team-name'>
                  <span>
                    <img className='fixture-image' src={fixture.teamAImage} alt={`${fixture?.subCategoryName} - ${fixture.teamA}`} />
                  </span>
                  <strong>{fixture.teamA}</strong>{' '}
                </p>
                {fixture?.teamB ? <p className='verses'>vs</p>: ''}
                {fixture?.teamB && <p className='team-name'>
                  <strong>{fixture.teamB}</strong>{' '}
                  <span><img className='fixture-image' src={fixture.teamBImage} alt={`${fixture?.subCategoryName} - ${fixture.teamB}`} /></span>
                </p>}
              </>
                :
              <div>
                <p className='team-name' style={{margin:'0px'}}>
                  <img className='fixture-image' src={fixture.teamAImage} alt={`${fixture?.subCategoryName} - ${fixture.teamA}`} />
                  <strong> {fixture.teamA}</strong>{' '}
                </p>
                {fixture?.teamB ? <p className='verses' style={{margin:'0px'}}>vs</p>: ''}
                {fixture?.teamB && <p className='team-name' style={{margin:'0px'}}>
                  <span><img className='fixture-image' src={fixture.teamBImage} alt={`${fixture?.subCategoryName} - ${fixture.teamB}`} /></span>
                  <strong>{fixture.teamB}</strong>{' '}
                </p>}
              </div>}
            </div>
          </div>
          <p className='match-time'>
              <Timer timerValue={getTimer(fixture)}></Timer>
          </p>
        </div>
      </Link>
    );
  }

  useEffect(() => {
    const category = categoryName ?? soccer;
    document.title = category ? PAGE_TITLES[category.toUpperCase()]: PAGE_TITLES.WEBSITE_TITLE;
    getAllFixtures();
  }, [categoryName, soccer]);

  return (
    <>
      {/* <Teams></Teams> */}
      <Row style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        <Col span={16} style={{paddingRight: '12px'}}>
          <Row className='flex-display'>
            <Space style={matches ? { marginBottom:'15px'}: { marginBottom: '5px', gap: '2px'}} wrap>
            <Link to={DEFAULT_LEAGUE_LINK} >
              <Button className='time-button' style={{ backgroundColor: '#169C6F' }} shape="round" size={buttonSize}>
                Leagues
              </Button>
            </Link>
              
              <Link to={DEFAULT_TEAM_LINK} >
                <Button className='time-button' style={{ backgroundColor: 'rgb(107 104 104)' }} shape="round" size={buttonSize}>
                  Teams
                </Button>
              </Link>

              
              <Link to={'/leagues/standings'} >
                <Button className='time-button' style={{ backgroundColor: '#5AB3BB' }} shape="round" size={buttonSize}>
                  Standings
                </Button>
              </Link>
              
            </Space>
          </Row>
          {/* <Row style={styles.flexDisplay}>
            <BCBanner type={'TOP'}></BCBanner>
          </Row> */}
          {/* <AdSenseBanner slot={ADS.MAIN.SLOT} type={ADS.MAIN.TYPE} /> */}
        </Col>
          {/* <AdSenseBanner slot="7807264218" type='square-display' /> */}
      </Row>
      <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
        <Col span={matches ? 20: 16} style={{ marginTop: '12px' }}>
          {categoryName && noFixtures ? 
          <div>
            <Title level={3}>No Fixtures Found For {categoryName}</Title>
            <Title level={3}>
              <Link to='/'><Button style={styles.homeButton}>Home</Button></Link>
            </Title>
          </div>:
            fixtures?.subCategories?.map((subCategory: any, index: any) => (
              subCategory?.games?.length > 0 ? (
                <SubCategoryCard key={index} subCategory={{ ...subCategory }} />
              ) : null
            ))         
          }
        </Col>
        {/* <Col span={6}>
          <AdSenseBanner slot={ADS.VERTICLE_MAIN.SLOT} type={ADS.VERTICLE_MAIN.TYPE} />
          <AdSenseBanner slot={ADS.SQUARE_DISPLAY.SLOT} type={ADS.SQUARE_DISPLAY.TYPE} />
          <AdSenseBanner slot={ADS.IN_ARTICLE.SLOT} type={ADS.IN_ARTICLE.TYPE} />
          <AdSenseBanner slot={ADS.IN_FEED.SLOT} type={ADS.IN_FEED.TYPE} />
        </Col> */}
      </Row>
      <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
        <Col span={matches ? 20: 16}>
        {!loader && !categoryName && !soccer && <MainDescription></MainDescription>}
        </Col>
      </Row>
      {loader ? <Loader /> : <></>}
    </>
  );
}
